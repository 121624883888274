<template>
  <div class="questionnaire-list">
    <Breadcrumb :breadcrumbList="list"></Breadcrumb>

    <div class="questionnaire-main">
      <!-- 筛选 -->
      <div class="screen">
        <div class="state state-right">
          <!--<div class="name">类别:</div>          
          <el-select v-model="entryType" placeholder="请选择" size="small" class="type-state" @change="getProjects(1)">
            <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>-->
          <el-radio-group v-model="entryType" size="small">
            <el-radio-button
              v-for="item in options2"
              :key="item.value"
              :label="item.value"
              >{{ item.label }}</el-radio-button
            >
          </el-radio-group>
        </div>
        <div class="state state-right">
          <div class="name">项目状态:</div>
          <el-select
            v-model="status"
            placeholder="请选择"
            size="small"
            class="app-state"
            @change="getProjects(1)"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- <div class="state"> -->
        <!--<div class="name">类别:</div>          
          <el-select v-model="entryType" placeholder="请选择" size="small" class="type-state" @change="getProjects(1)">
            <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>-->
        <!-- <el-radio-group v-model="entryType" size="small" @input="getProjects(1)">
            <el-radio-button v-for="item in options2" :key="item.value" :label="item.value">{{item.label}}</el-radio-button>
          </el-radio-group>
        </div> -->
        <div class="state state-right">
          <div class="name">执行地区:</div>
          <el-select
            v-model="countryType"
            placeholder="请选择执行地区"
            size="small"
            class="app-state"
          >
            <el-option
              v-for="item in options3"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="box">
          <el-select
            v-model="searchCateId"
            placeholder="搜索类型"
            size="small"
            class="app-name"
          >
            <el-option
              v-for="item in searchType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>

          <input
            :placeholder="`请输入项目${
              searchCateId == 1 ? 'ID' : searchCateId == 2 ? '编号' : '名称'
            }`"
            v-model="key"
            maxlength="1000"
          />
        </div>

        <div class="box">
          <el-button type="primary" @click="getProjects(1)"
            >搜索 <i class="el-icon-search el-icon--right"
          /></el-button>
          <el-button class="senior" type="primary" @click="advanced = !advanced"
            >高级搜索 <i class="el-icon-search el-icon--right"
          /></el-button>
        </div>

        <div class="advanced-query" v-if="advanced">
          <div class="advanced-content">
            <div class="name">项目开始日期：</div>
            <el-date-picker
              v-model="date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="small"
              @change="changeDate"
              popper-class="picker-date-style"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
          <div class="footer-boottom">
            <el-button type="primary" @click="getProjects(1)"
              >搜索 <i class="el-icon-search el-icon--right"
            /></el-button>
            <el-button class="empty" @click="cleanUp" type="primary"
              >清空条件 <i class="el-icon-refresh el-icon--right"
            /></el-button>
            <el-button class="close" @click="advanced = false" type="primary"
              >关闭 <i class="el-icon-switch-button el-icon--right"
            /></el-button>
          </div>
        </div>
      </div>
      <div class="table">
        <el-table
          height="540"
          :data="questionnaireList"
          header-row-class-name="header-row"
          v-loading="surveyLoading"
          :empty-text="
            surveyLoading === false && questionnaireList?.length === 0
              ? '暂无数据'
              : '  '
          "
          element-loading-text="加载中.."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255, 255, 255, .1)"
        >
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <el-table-column prop="projectId" label="ID号" width="100">
          </el-table-column>
          <el-table-column prop="number" label="项目编号" width="150">
          </el-table-column>
          <el-table-column prop="title" label="问卷名称" min-width="100">
            <template slot-scope="scope">
              <el-tooltip placement="top" effect="dark">
                <div slot="content">{{ scope.row.title }}</div>
                <div class="no-wrap">{{ scope.row.title }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" width="110">
            <template slot-scope="scope">
              <div class="row-state">
                <span
                  v-if="scope.row.status === 0"
                  style="background: #698af7"
                ></span>
                <span
                  v-if="scope.row.status === 1"
                  style="background: #ff8940"
                ></span>
                <span
                  v-if="scope.row.status === 2"
                  style="background: #72daff"
                ></span>
                <span
                  v-if="scope.row.status === 3"
                  style="background: #81e0af"
                ></span>
                <span
                  v-if="scope.row.status === 4"
                  style="background: #f56c6c"
                ></span>
                <div v-if="scope.row.status === 0">进行中</div>
                <div v-if="scope.row.status === 1">暂停</div>
                <div v-if="scope.row.status === 2">数据审核</div>
                <div v-if="scope.row.status === 3">已完成</div>
                <div v-if="scope.row.status === 4">已撤销</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="IR" label="难易度" width="100" sortable>
            <template slot-scope="scope"> {{ scope.row.IR }} % </template>
          </el-table-column>

          <el-table-column prop="conversion" label="实际转化率" width="120">
            <template slot-scope="scope">
              <span
                style="color: #f56c6c"
                v-if="
                  Math.round(scope.row.conversion * 100) - scope.row.IR > 10
                "
                >{{ Math.round(scope.row.conversion * 100) }} %</span
              >
              <span v-else>{{ Math.round(scope.row.conversion * 100) }} %</span>
            </template>
          </el-table-column>

          <el-table-column
            prop="cpi2Complete"
            label="单价"
            width="100"
            sortable
          >
            <template slot-scope="scope"
              >￥ {{ scope.row.cpi2Complete }}
            </template>
          </el-table-column>
          <el-table-column
            prop="maxComplete"
            label="配额量"
            width="100"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="extraCpi2Complete"
            label="额外单价"
            width="100"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.extraCpi2Complete !== null"
                >￥ {{ scope.row.extraCpi2Complete }}</span
              >
              <span v-else>_</span>
            </template>
          </el-table-column>
          <el-table-column prop="random" label="是否随机" width="100">
            <template slot-scope="scope">
              {{ scope.row.random ? "是" : "否" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="countryId"
            label="执行国家地区"
            min-width="120"
          >
            <template slot-scope="scope">
              <div class="no-wrap">
                {{ scope.row.countryId | getCountrys(countrys) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="updateTime" label="更新时间" width="160">
          </el-table-column>
          <el-table-column label="操作" width="220" fixed="right">
            <template slot-scope="scope">
              <div class="operate">
                <div class="left" @click="quotaDetails(scope.row.projectId)">
                  配额详情
                </div>
                <div @click="copyContent(scope.row.uri, 1)">复制链接</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="pagination-box">
        <span class="total-pages">共{{ PageCount }}页</span>
        <el-pagination
          @size-change="getProjects"
          @current-change="getProjects"
          :current-page.sync="page"
          :page-size="size"
          background
          layout="prev, pager, next,jumper"
          :total="total"
        >
        </el-pagination>
      </div>

      <div class="footer-fa">
        <Footer></Footer>
      </div>
    </div>

    <el-dialog
      title="配额详情"
      :visible.sync="dialogVisible"
      width="624px"
      :before-close="handleClose"
    >
      <div class="content" v-html="remark"></div>

      <div class="button-fa">
        <el-button @click="copyContent(remark, 2)">复制</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import Footer from "@/components/Footer/homePageFooter.vue";
import { getProjects, getCountryAll, getProjectsDetails } from "@/api/app";
export default {
  components: { Breadcrumb, Footer },
  filters: {
    getCountrys(value, countrys) {
      let name = null;
      countrys.forEach((element) => {
        if (element.id === value) {
          name = element.name;
          return;
        }
      });
      return name;
    },
  },
  data() {
    return {
      list: [
        {
          name: "应用列表",
          path: null,
        },
        {
          name: "问卷集web",
          path: "/application",
        },
        {
          name: "问卷列表",
          path: null,
        },
      ],
      value: "",
      value1: "",
      advanced: false,
      options: [
        {
          value: null,
          label: "全部",
        },
        {
          value: 0,
          label: "进行中",
        },
        {
          value: 1,
          label: "暂停",
        },
        {
          value: 2,
          label: "数据审核",
        },
        {
          value: 3,
          label: "已完成",
        },
        {
          value: 4,
          label: "已撤销",
        },
      ],
      options2: [
        {
          value: null,
          label: "全部",
        },
        {
          value: 1,
          label: "个案",
        },
        {
          value: 2,
          label: "API",
        },
      ],
      options3: [
        {
          value: 1,
          label: "海外",
        },
        {
          value: 2,
          label: "中国大陆",
        },
      ],
      countryType: 2,
      questionnaireList: null,
      dialogVisible: false,
      countrys: null,
      remark: null, //配额详情
      date: null,
      searchType: [
        {
          value: 1,
          label: "问卷ID",
        },
        {
          value: 2,
          label: "问卷编号",
        },
        {
          value: 3,
          label: "问卷名称",
        },
      ],
      total: null,
      page: 1,
      size: 10,
      PageCount: null,
      appId: null,
      status: null,
      startDate: null,
      endDate: null,
      searchCateId: 1,
      key: null,
      entryType: 1,
      surveyLoading: true,
    };
  },
  created() {
    this.appId = parseInt(this.$route.query.id);
    //先获取全部国家
    getCountryAll().then((res) => {
      if (res.status === 200) {
        this.countrys = res.data;
        //获取列表
        this.getProjects();
      }
    });
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    //改变日期范围
    changeDate() {
      if (this.date) {
        this.startDate = this.date[0];
        this.endDate = this.date[1];
      } else {
        this.startDate = "";
        this.endDate = "";
      }
      // this.page = 1;
      // this.getProjects();
    },
    //复制
    copyContent(data, type) {
      if (type === 2) {
        data = data.replace(/<(?!\/?img|\/?a)[^<>]*>/gi, "");
        data = data.replace("&nbsp;", "");
        data = data.replace(/\s/g, "");
      }
      console.log("data", data);
      this.$copyText(data).then(
        () => {
          this.$message.success("复制成功");
        },
        () => {
          this.$message.error("复制失败");
        }
      );
      this.handleClose();
    },
    //配额详情
    quotaDetails(id) {
      this.remark = null;
      getProjectsDetails(id).then((res) => {
        if (res.status === 200) {
          this.dialogVisible = true;
          this.remark = res.data.remark;
        }
      });
    },
    //获取问卷列表
    getProjects(type) {
      this.surveyLoading = true;
      this.questionnaireList = null;

      if (type === 1) {
        this.page = 1;
      }
      const params = {
        page: this.page,
        size: this.size,
        appId: this.appId,
        status: this.status,
        startDate: this.startDate,
        endDate: this.endDate,
        searchCateId: this.searchCateId,
        key: this.key,
        entryType: this.entryType,
        countryType: this.countryType,
      };

      getProjects(params).then((res) => {
        console.log("问卷列表", res.data.data);
        this.questionnaireList = res.data.data;
        this.total = res.data.dataCount;
        this.PageCount = res.data.pageCount;

        this.surveyLoading = false;
        this.advanced = false;
      });
    },
    //cleanUp
    cleanUp() {
      this.startDate = null;
      this.endDate = null;
      this.countryType = 2;
      this.status = null;
      this.searchCateId = 1;
      this.key = null;
      this.entryType = 1;
      this.date = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.questionnaire-list {
  padding: 20px 0 0 20px;
  height: calc(100% - 20px);
}

.questionnaire-main {
  width: 100%;
  min-height: calc(100% - 68px);
  background-color: #fff;
  margin-top: 20px;

  .screen {
    display: flex;
    //justify-content: space-between;
    align-items: center;
    padding: 21px 24px 0 24px;
    position: relative;
    flex-wrap: wrap;
    // min-width: 100px;

    .app-name {
      width: 100px;
    }
    .state-right {
      margin-right: 20px;
    }
    .state {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .name {
        font-size: 14px;
        margin-right: 7px;
      }

      .app-state {
        width: 120px;
      }

      .type-state {
        width: 100px;
      }
    }

    .box {
      display: flex;
      align-items: center;
      // margin-left: 20px;
      margin-bottom: 20px;
      input {
        width: 184px;
        height: 32px;
        border: 1px solid #dddddd;
        border-radius: 4px;
        padding-left: 10px;
        padding-right: 10px;
        margin-right: 20px;
        margin-left: 10px;
        box-sizing: border-box;
      }

      .el-button {
        background-color: #4578ff;
        color: #fff;
        height: 32px;
        width: 84px;
        padding: 0;
      }
      .senior {
        width: 110px;
      }
    }
    .advanced-query {
      position: absolute;
      width: 100%;
      // height: 100px;
      background-color: #fff;
      left: 0;
      top: 55px;
      z-index: 999;
      padding: 21px 24px 24px 24px;
      box-sizing: border-box;
      .advanced-content {
        display: flex;
        align-items: center;
        .name {
          font-size: 14px;
          margin-right: 7px;
        }
        // .el-date-editor {
        //   // margin-left: 40px;
        // }
        .state-right {
          margin-right: 40px;
        }
      }
      .footer-boottom {
        display: flex;
        justify-content: flex-end;
        .el-button {
          background-color: #4578ff;
          color: #fff;
          height: 32px;
          width: 84px;
          padding: 0;
        }
        .empty {
          background-color: #4578ff;
          width: 110px;
          border-color: #4578ff;
        }
        .close {
          background-color: #f4f4f4;
          color: #000;
          border-color: #f4f4f4;
        }
      }
    }
  }
}

.table {
  padding: 0 24px 0 24px;
  width: calc(100% - 48px);

  .el-table {
    width: 100%;
    // margin-top: 27px;

    /deep/ .header-row {
      background-color: #f5f7fd;

      .el-table__cell {
        background-color: #f5f7fd;
        color: #06052b;
        font-size: 14px;
        font-weight: normal;
        text-align: center;
      }
    }

    /deep/ .el-table__cell {
      text-align: center;
    }

    .no-wrap {
      white-space: nowrap;
      word-break: keep-all;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .row-state {
      display: flex;
      align-items: center;

      span {
        width: 7px;
        height: 7px;
        background: #698af7;
        border-radius: 7px;
        margin-right: 8px;
        margin-left: 10px;
      }
    }

    .operate {
      display: flex;
      justify-content: center;
      font-size: 14px;
      color: #4578ff;

      .left {
        margin-right: 20px;
      }

      div {
        cursor: pointer;
      }
    }
  }
}

.pagination-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;

  .total-pages {
    color: #323233;
    font-size: 14px;
    margin-right: 18px;
  }

  .el-pagination {
    margin-right: 20px;
  }
}

.footer-fa {
  padding-right: 20px;
  background-color: #fff;

  /deep/ .home-page-footer {
    margin-bottom: 0 !important;
    padding-bottom: 25px;
  }
}

.questionnaire-list {
  /deep/ .el-dialog__header {
    padding: 24px 24px 0 24px;

    .el-dialog__title {
      font-weight: bold;
      font-size: 16px;
    }

    .el-dialog__close {
      color: #000;
    }
  }

  /deep/ .el-dialog__body {
    padding: 24px;

    .content {
      padding: 0 40px;
      color: #666666;
      font-size: 16px;
      line-height: 36px;
    }

    .button-fa {
      margin-top: 20px;
      display: flex;
      justify-content: center;

      .el-button {
        width: 84px;
        height: 32px;
        background: #4578ff;
        border-radius: 4px 4px 4px 4px;
        color: #fff;
        padding: 0;
      }
    }
  }
}
</style>